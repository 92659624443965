import * as React from 'react';
import { connect } from 'react-redux';
import * as AuthenticationStore from '../../store/Authentication'
import * as AlertStore from '../../store/Alerts'
import { ApplicationState } from '../../store';
import { FormGroup } from 'reactstrap';
import './Login.css'
import { IAuthenticationActionCreators } from '../../store/Authentication';
import { IAlertActionCreators } from '../../store/Alerts';
import { IUser, ICustomerContact, IAdministrator } from '../../models/User';
import { Trans, useTranslation } from 'react-i18next';
import Context from '../../configuration/context';
import { authenticationServiceFactory, IError } from '../../services/authenticationService';
import { useEffect, useMemo } from 'react';
import { appInsights } from '../../services/applicationInsights';
import CustomLogin from '../controls/CustomLogin/CustomLogin';
import customLoginService from '../../services/customLoginService';
import MSLogin from '../controls/MSLogin/MSLogin';

export type ILoginProps =
    IAuthenticationActionCreators &
    IAlertActionCreators &
    {
        authenticationState?: AuthenticationStore.UserState;
    }
export interface ICustomerResponsible {
    displayName: string;
    email: string;
    phone: string;
    imageUrl: string;
}

export interface ISiteReference {
    customerName: string;
    customerId: string;
    customerContacts?: ICustomerResponsible[];
    invoicesAreAvailable: boolean;
    orderServiceIsAvailable: boolean;
    movieServiceIsAvailable: boolean;
}

export interface IUserInRole {
    availableCustomers?: ISiteReference[],
    email?: string,
    name: string,
    role: string,
    selectedCustomer?: ISiteReference
}

const Login = function ({ setLoginState, authenticationState }: ILoginProps) {

    const { t, i18n  } = useTranslation('login');
    const [showMsLogin, setShowMsLogin] = React.useState(false);

    const loginService = customLoginService.Create(Context.APIUrl + "/api/v1", { setLoginState }, authenticationState, i18n.language );

    return (
        <div className="login-container center">
            <div className="col-md-6 offset-md-3">
                <h2>{t('LOGIN')}</h2>
                {!showMsLogin && <>
                    <CustomLogin loginService={loginService} onOldLogin={() => {
                        setShowMsLogin(true);                                
                    }} />
                </>}
                {showMsLogin &&<>
                    <MSLogin authenticationState={authenticationState} setLoginState={setLoginState} />
                    <p className="login-info-section">
                        <Trans i18nKey="INFO_NEW_LOGIN" ns="login"><a onClick={() => { setShowMsLogin(false); }}></a></Trans>
                    </p>
                </>}                    
                <p className="login-info-section" style={{"marginTop": "4em" }}>
                    <Trans i18nKey="MIGRATION_INFO" ns="login"><h3 style={{"fontSize": "1.3em" }}></h3><a href="https://webbportal2.avonova.se"></a></Trans>
                </p>
            </div>
        </div>)

};

export default connect(
    (appState: ApplicationState) => (
        { authenticationState: appState.authentication }),
    { ...AuthenticationStore.actions, ...AlertStore.actionCreators } 
)(Login);